// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis'
import Instructions from 'pages/Instructions'
import DataMapping from 'pages/DataMapping'
import ErrorCodes from 'pages/ErrorCodes'
import ReferenceQueries from 'pages/ReferenceQueries'
import Introduction from "./pages/Introduction"
import FunctionalDescription from "./pages/FunctionalDescription"
import Import from "./pages/Import"
import Export from "./pages/Export"
import TestingTools from "./pages/TestingTools"
import Postman from "./pages/Postman"
import SoapUI from "./pages/SoapUI"
import Curl from "./pages/Curl"
import { Admin } from 'pages/Admin'

// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import NavBar from 'components/NavBar'
import Feedback from './components/Feedback'
import ApiSearch from './components/ApiSearch'

import { isAdmin, isRegistered, init, login, logout } from 'services/self'
import './index.css'

window.Buffer = window.Buffer || require('buffer').Buffer;

loadFragments()

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            isRegistered()
                ? <Component {...props} />
                : <Redirect to='/' />
        )}
    />
)

export const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest} render={props => (
        isAdmin()
            ? <Component {...props} />
            : <Redirect to='/' />
    )}
    />
)

// To shut up a dev warning
const HomeWrap = props => <Home {...props} />
const GettingStartedWrap = props => <GettingStarted {...props} />
const DashboardWrap = props => <Dashboard {...props} />

class App extends React.Component {
  constructor () {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
  }

  render () {
    return (
        <BrowserRouter>
          <>
            <NavBar />
            <GlobalModal />
            <Switch>
              <Route exact path='/' component={HomeWrap} />
              <Route
                  exact
                  path='/index.html'
                  component={() => {
                    const { action } = queryString.parse(window.location.search)
                    if (action === 'login') {
                      login()
                    } else if (action === 'logout') {
                      logout()
                    }
                    return <Redirect to='/' />
                  }}
              />
              <Route path='/getting-started' component={GettingStartedWrap} />
              <Route exact path='/apis/:apiId/instructions' component={Instructions}/>
              <Route exact path='/apis/:apiId/data-mapping' component={DataMapping}/>
              <Route exact path='/apis/:apiId/error-codes' component={ErrorCodes}/>
              <Route exact path='/apis/:apiId/reference-queries' component={ReferenceQueries}/>
              <Route exact path='/apis/:apiId/introduction' component={Introduction}/>
              <Route exact path='/apis/:apiId/functional-description' component={FunctionalDescription}/>
              <Route exact path='/apis/:apiId/functional-description/import' component={Import}/>
              <Route exact path='/apis/:apiId/functional-description/export' component={Export}/>
              <Route exact path='/apis/:apiId/testing-tools' component={TestingTools}/>
              <Route exact path='/apis/:apiId/testing-tools/postman' component={Postman}/>
              <Route exact path='/apis/:apiId/testing-tools/soapui' component={SoapUI}/>
              <Route exact path='/apis/:apiId/testing-tools/curl' component={Curl}/>
              <RegisteredRoute path='/dashboard' component={DashboardWrap} />
              <AdminRoute path='/admin' component={Admin} />
              <Route exact path='/apis' component={Apis} />
              <Route exact path='/apis/search' component={ApiSearch} />
              <Route exact path='/apis/:apiId' component={Apis} />
              <Route path='/apis/:apiId/:stage' component={Apis} />
              <Route path='/login' render={() => { login(); return <Redirect to='/' /> }} />
              <Route path='/logout' render={() => { logout(); return <Redirect to='/' /> }} />
              <Route component={() => <h2>Page not found</h2>} />

            </Switch>
            {feedbackEnabled && <Feedback />}
            <AlertPopup />
          </>
        </BrowserRouter>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))