// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
// import { Link } from 'react-router-dom'

// semantic-ui
import { Header, Segment, Container, Button } from 'semantic-ui-react'

import {getLoginRedirectUrl} from 'services/self'
import {cognitoClientId, cognitoDomain} from "../services/api";

function getCognitoUrl (type) {
    const redirectUri = getLoginRedirectUrl()
    return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}
export const HomePage = observer(() => (
  <>
    <Segment vertical textAlign='center' style={{ color: 'whitesmoke', backgroundColor: 'slategray', padding: '40px 0px', margin: '0px !important' }}>
        {/*<Image centered size='small' src='/custom-content/home-image.png' />*/}
      <Header as='h1' style={{ color: 'whitesmoke' }}>{fragments.Home.header}</Header>
      <p>{fragments.Home.tagline}</p>
      <a href={getCognitoUrl('login')}>
        <Button positive style={{background:"rgb(52,120,195) !important"}}>{fragments.Home.gettingStartedButton}</Button>
      </a>
    </Segment>
    <Segment vertical style={{ padding: '40px 0px', margin: '0 !important' }}>
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Segment>
  </>
))

export default HomePage
