// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Redirect } from 'react-router-dom'

// semantic-ui
import { Menu, Loader } from 'semantic-ui-react'

// store
import { observer } from 'mobx-react'
import { store } from 'services/state'

// utilities
import _ from 'lodash'
import Sidebar from 'components/Sidebar/Sidebar'
import SidebarHeader from 'components/Sidebar/SidebarHeader'
import MenuLink from 'components/MenuLink'
import {fragments} from "../services/get-fragments";

function createApiURL(component, id){
  return "/apis/"+id+"/"+component
}

function getApisWithStages (selectedApiId, selectedStage, activateFirst) {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    id: api.apiStage || api.id,
    title: api.swagger.info.title,
    route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
    active: (
        (selectedApiId && (api.id === selectedApiId || api.apiId === selectedApiId)) &&
        (!selectedStage || api.apiStage === selectedStage)
    ),
    stage: api.apiStage
  }))

  return _.toPairs(_.groupBy(apiList, 'group'))
      .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title }))
}

export default observer(function ApisMenu (props) {
  // If we're still loading, display a spinner.
  // If we're not loading, and we don't have any apis, display a message.
  // If we're not loading, and we have some apis, render the appropriate api subsections for apiGateway and generic apis
  if (!store.apiList.loaded) {
    return <Loader active />
  }

  const apiGroupList = getApisWithStages(
      props.activateFirst && props.path.params.apiId,
      props.path.params.stage,
      props.activateFirst
  )

  if (!apiGroupList.length) {
    return <p style={{ padding: '13px 16px', color: 'whitesmoke' }}>No APIs Published</p>
  }

  if (props.activateFirst && !props.path.params.apiId) {
    return <Redirect to={apiGroupList[0].apis[0].route+"/introduction"} />
  }

  return (
      <Sidebar>
        {/*<SidebarHeader
        as={Link}
        className='item'
        to='/apis/search'
        active={props.path.url === '/apis/search'}
        style={{ fontWeight: '400', fontSize: '1em' }}
      >
        Search APIs
      </SidebarHeader>*/}

        <SidebarHeader>API</SidebarHeader>

        <>
          {apiGroupList.map(({ apis, title, group, active }) => (


              <MenuLink label={"Documentation"}>
                {"Documentation"}
                {/*{apis[0].stage ? (*/}
                    <Menu.Menu>

                      {/*{apis.map(({ route, stage, active, id }) => (
                          <MenuLink label={"Active-api"} key={id} to={route} active={active}>
                            {stage}
                          </MenuLink>
                      ))}*/}
                      {<MenuLink label={"Introduction"} to={createApiURL("introduction",group)} active={active} >
                        {fragments.Introduction.title}
                      </MenuLink>}
                      {<MenuLink label={"Functional Description"} to={createApiURL("functional-description",group)} active={active} >
                        {fragments.FunctionalDescription.title}
                      </MenuLink>}
                      {<MenuLink label={"Import"} to={createApiURL("functional-description/import",group)} active={active} >
                        {fragments.Import.title}
                      </MenuLink>}
                      {<MenuLink label={"Export"} to={createApiURL("functional-description/export",group)} active={active} >
                        {fragments.Export.title}
                      </MenuLink>}
                      {<MenuLink label={"Data Mapping"} to={createApiURL("data-mapping",group)} active={active} >
                        {fragments.DataMapping.title}
                      </MenuLink>}
                      {<MenuLink label={"Testing Tools"} to={createApiURL("testing-tools",group)} active={active} >
                        {fragments.TestingTools.title}
                      </MenuLink>}
                      {<MenuLink label={"Postman"} to={createApiURL("testing-tools/postman",group)} active={active} >
                        {fragments.Postman.title}
                      </MenuLink>}
                      {<MenuLink label={"SoapUI"} to={createApiURL("testing-tools/soapui",group)} active={active} >
                        {fragments.SoapUI.title}
                      </MenuLink>}
                      {<MenuLink label={"Curl"} to={createApiURL("testing-tools/curl",group)} active={active} >
                        {fragments.Curl.title}
                      </MenuLink>}
                      {<MenuLink label={"Error Codes"} to={createApiURL("error-codes",group)} active={active} >
                        {fragments.ErrorCodes.title}
                      </MenuLink>}
                      {<MenuLink label={"Reference Queries"} to={createApiURL("reference-queries",group)} active={active} >
                        {fragments.ReferenceQueries.title}
                      </MenuLink>}


                    </Menu.Menu>
                {/*) : null}*/}
              </MenuLink>
          ))}
        </>
      </Sidebar>
  )
})