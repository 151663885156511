
import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
//import { Container } from 'semantic-ui-react'
import PageWithSidebar from "../components/PageWithSidebar";
import ApisMenu from "../components/ApisMenu";
//import {store} from "../services/state";
import { Container} from 'semantic-ui-react'

export default observer(class ApisPage extends React.Component {
    containerRef = React.createRef()
    render () {
        return (
            <PageWithSidebar
                sidebarContent={<ApisMenu path={this.props.match} activateFirst={true}/>}
                SidebarPusherProps={{className: 'swagger-section'}}
            >
                <Container style={{ padding: '40px' }}>
                    <fragments.Introduction.jsx />
                </Container>
            </PageWithSidebar>
        )
    }
})
